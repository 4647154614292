import { Auth } from "aws-amplify";
import Cookies from "js-cookie";
import Router from "next/router";

export function diff(x, y) {
	let keys = [...Object.keys(x), ...Object.keys(y)],
		diff = {};
	keys.map(key => {
		if (JSON.stringify(y[key]) !== JSON.stringify(x[key])) {
			if (x[key] !== undefined) diff[key] = x[key];
		}
	});
	return diff;
}

export function numberWithCommas(x) {
	if (x === undefined) return "0";
	x = x.toString();
	var pattern = /(-?\d+)(\d{3})/;
	while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
	return x;
}

export function removeCognitoFromLocalStorage() {
	for (let key in localStorage) {
		if (key.includes("CognitoIdentityServiceProvider")) localStorage.removeItem(key);
	}
}

export const handleLogout = async (e, authContext) => {
	e.preventDefault();
	try {
		Auth.signOut();
		Cookies.remove("authToken");
		removeCognitoFromLocalStorage();

		let cookies = Cookies.get();
		Object.keys(cookies).map(key => {
			if (key.includes("CognitoIdentityServiceProvider")) Cookies.remove(key);
		});
		authContext.setAuth({ attributes: {} }, false);
		await Auth.signOut();
		let message = "Logged Out Successfully";
		successToaster(message);
		Router.replace("/", undefined, { shallow: false });
	} catch (err) {
		console.error(err.message);
	}
};
